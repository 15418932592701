@font-face {
  font-family: 'CenturyGothic';
  src: url('./assets/fonts/CenturyGothic.ttf') format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBold';
  src: url('./assets/fonts/CenturyGothicBold.ttf') format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicItalic';
  src: url('./assets/fonts/CenturyGothicItalic.ttf') format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBoldItalic';
  src: url('./assets/fonts/CenturyGothicBoldItalic.ttf') format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicLight';
  src: url('./assets/fonts/CenturyGothicLight.ttf') format('TrueType');
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('TrueType');
}
@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./assets/fonts/Montserrat-ExtraBold.ttf') format('TrueType');
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('TrueType');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('TrueType');
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('TrueType');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('TrueType');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./assets/fonts/Montserrat-ExtraBold.ttf') format('TrueType');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('TrueType');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('TrueType');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('TrueType');
}

@font-face {
  @import url('https://fonts.googleapis.com/css?family=Teko&display=swap');
  font-family: 'Teko', sans-serif;
}

html,
body,
#root {
  min-height: 100%;
  height: 100%;
  font-family: 'CenturyGothic', sans-serif;
}

body {
  margin: 0;
}

*,
:focus {
  box-sizing: border-box;
  outline: 0;
}

a,
button,
input,
select,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.MuiInputBase-root {
  font-family: 'CenturyGothic', sans-serif;
}

.MuiPopover-paper {
  border-radius: 15px !important;
}

.react-switch-handle {
  pointer-events: none !important;
}

.MuiSelect-select {
  padding-left: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiTableCell-stickyHeader {
  background-color: #ebebeb !important;
}

.input-courses .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: unset !important;
}
.input-courses .MuiInput-underline:before {
  transition: unset !important;
  content: unset !important;
}

ul .react-tabs__tab--selected::marker,
ul .react-tabs__tab::marker {
  display: none;
}

ul .react-tabs__tab--selected,
ul .react-tabs__tab {
  list-style-image: none;
  list-style-type: none;
}

.Toastify__toast {
  padding: 16px !important;
}

.Toastify__toast--default {
  border-radius: 20px !important;
}

sup {
  font-size: 0.7em;
  line-height: 1em;
}

.Toastify__toast-container {
  z-index: 999999 !important;
}
